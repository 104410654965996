import { Checkbox, styled } from "@mui/material";
import { TStyledCheckboxProps } from "../../../types/styled/types";

const StyledCheckbox = styled(Checkbox, {
	shouldForwardProp: (prop) => prop !== "isError",
})<TStyledCheckboxProps>(({ isError }) => ({
	"&.Mui-checked": {
		color: "#00BEB4 !important",
	},
	"& .MuiIconButton-label": {
		position: "relative",
		zIndex: 0,
	},
	"& .MuiSvgIcon-root": {
		fontSize: 28,
	},
	padding: "0px !important",
	"&:not(.Mui-checked) .PrivateSwitchBase-input": {
		width: "24px",
		height: "24px",
		top: "auto",
		left: "auto",
		opacity: "1",
		visibility: "hidden",
		'-moz-appearance': 'initial',

		"&::before": {
			content: '""',
			position: "absolute",
			background: isError ? "#FDEDEB !important" : "#EBECEE !important",
			height: "100%",
			width: "100%",
			borderRadius: "4px !important",
			visibility: "visible",
		},
	},
	'-moz-appearance': 'initial',
	color: isError ? "#FDEDEB !important" : "#EBECEE !important",
	borderRadius: "50% !important",
}));

const CustomCheckbox = (props: TStyledCheckboxProps) => {
	return (
		<StyledCheckbox
			key={props.key}
			onChange={props.onChange}
			checked={props.checked}
			isError={props.isError}
		/>
	);
};

export { CustomCheckbox };
